import _ from 'lodash';
import { areComponentsEqual as areComponentsEqualHot } from 'react-hot-loader';

export function isPromise(promise) {
	return promise ? _.isFunction(promise.finally) : false;
}

export const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

const countryCodes = [
	'ad',
	'ae',
	'af',
	'ag',
	'ai',
	'al',
	'am',
	'ao',
	'aq',
	'ar',
	'as',
	'at',
	'au',
	'aw',
	'ax',
	'az',
	'ba',
	'bb',
	'bd',
	'be',
	'bf',
	'bg',
	'bh',
	'bi',
	'bj',
	'bl',
	'bm',
	'bn',
	'bo',
	'bq',
	'br',
	'bs',
	'bt',
	'bv',
	'bw',
	'by',
	'bz',
	'ca',
	'cc',
	'cd',
	'cf',
	'cg',
	'ch',
	'ci',
	'ck',
	'cl',
	'cm',
	'cn',
	'co',
	'cr',
	'cu',
	'cv',
	'cw',
	'cx',
	'cy',
	'cz',
	'de',
	'dj',
	'dk',
	'dm',
	'do',
	'dz',
	'ec',
	'ee',
	'eg',
	'eh',
	'er',
	'es',
	'et',
	'eu',
	'fi',
	'fj',
	'fk',
	'fm',
	'fo',
	'fr',
	'ga',
	'gb-eng',
	'gb-nir',
	'gb-sct',
	'gb-wls',
	'gb',
	'gd',
	'ge',
	'gf',
	'gg',
	'gh',
	'gi',
	'gl',
	'gm',
	'gn',
	'gp',
	'gq',
	'gr',
	'gs',
	'gt',
	'gu',
	'gw',
	'gy',
	'hk',
	'hm',
	'hn',
	'hr',
	'ht',
	'hu',
	'id',
	'ie',
	'il',
	'im',
	'in',
	'io',
	'iq',
	'ir',
	'is',
	'it',
	'je',
	'jm',
	'jo',
	'jp',
	'ke',
	'kg',
	'kh',
	'ki',
	'km',
	'kn',
	'kp',
	'kr',
	'kw',
	'ky',
	'kz',
	'la',
	'lb',
	'lc',
	'li',
	'lk',
	'lr',
	'ls',
	'lt',
	'lu',
	'lv',
	'ly',
	'ma',
	'mc',
	'md',
	'me',
	'mf',
	'mg',
	'mh',
	'mk',
	'ml',
	'mm',
	'mn',
	'mo',
	'mp',
	'mq',
	'mr',
	'ms',
	'mt',
	'mu',
	'mv',
	'mw',
	'mx',
	'my',
	'mz',
	'na',
	'nc',
	'ne',
	'nf',
	'ng',
	'ni',
	'nl',
	'no',
	'np',
	'nr',
	'nu',
	'nz',
	'om',
	'pa',
	'pe',
	'pf',
	'pg',
	'ph',
	'pk',
	'pl',
	'pm',
	'pn',
	'pr',
	'ps',
	'pt',
	'pw',
	'py',
	'qa',
	're',
	'ro',
	'rs',
	'ru',
	'rw',
	'sa',
	'sb',
	'sc',
	'sd',
	'se',
	'sg',
	'sh',
	'si',
	'sj',
	'sk',
	'sl',
	'sm',
	'sn',
	'so',
	'sr',
	'ss',
	'st',
	'sv',
	'sx',
	'sy',
	'sz',
	'tc',
	'td',
	'tf',
	'tg',
	'th',
	'tj',
	'tk',
	'tl',
	'tm',
	'tn',
	'to',
	'tr',
	'tt',
	'tv',
	'tw',
	'tz',
	'ua',
	'ug',
	'um',
	'un',
	'unknown',
	'us',
	'uy',
	'uz',
	'va',
	'vc',
	've',
	'vg',
	'vi',
	'vn',
	'vu',
	'wf',
	'ws',
	'ye',
	'yt',
	'za',
	'zm',
	'zw'
];
export const trafficSourcesTitles = {
    'ADVERTISING': 'The viewer was referred to the video by an advertisement.',
    'ANNOTATION': 'Viewers reached the video by clicking on an annotation in another video.',
    'CAMPAIGN_CARD': 'Views originated from claimed, user-uploaded videos that the content owner used to promote the viewed content.',
    'END_SCREEN': ' The views were referred from the end screen of another video.',
    'EXT_URL': 'The video views were referred from a link on another website.',
    'NO_LINK_EMBEDDED': 'The video was embedded on another website when it was viewed.',
    'NO_LINK_OTHER': 'YouTube did not identify a referrer for the traffic.',
    'NOTIFICATION': 'The video views were referred from an email or notification from YouTube.',
    'PLAYLIST': 'The video views occurred while the video was being played as part of a playlist.',
    'PROMOTED': 'The video views were referred from an unpaid YouTube promotion.',
    'RELATED_VIDEO': 'The video views were referred from a related video listing on another video watch page.',
    'SUBSCRIBER': 'The video views were referred from feeds on the YouTube homepage or from YouTube subscription features.',
    'YT_CHANNEL': 'The video views occurred on a channel page.',
    'YT_OTHER_PAGE': 'The video views were referred from a link other than a search result or related video link that appeared on a YouTube page.',
    'YT_PLAYLIST_PAGE': 'The video views originated from a page that lists all of the videos in a playlist.',
    'YT_SEARCH': 'The video views were referred from YouTube search results.'
};

export function countryImage(countryCode) {
	if(_.isString(countryCode)) {
		const lowered = countryCode.toLowerCase();
		if (_.includes(countryCodes, lowered)) {
			return `https://viewiq-ui-assets.s3.amazonaws.com/flags/${lowered}.svg`;
		}
	}
	return 'https://viewiq-ui-assets.s3.amazonaws.com/flags/unknown.svg';
}

export function generateAbbreviation(string) {
	if(!_.isString(string)) {
		return '';
	}
	return string.match(/\b([a-z])/gi).join('').toUpperCase();
}

export function areComponentsEqual(component1, component2) {
	return areComponentsEqualHot(component1, component2);
}

export function stringifyFileSize(number) {
	const oneKB = 1024;
	const oneMB = oneKB*oneKB;
	if(number < oneKB) {
		return number + 'bytes';
	} else if(number > oneKB && number < oneMB) {
		return (number/oneKB).toFixed(1) + 'KB';
	} else if(number > oneMB) {
		return (number/oneMB).toFixed(1) + 'MB';
	}
}

export function imageBase64ToBlob(img) {
	const data = atob((img).substring('data:image/jpeg;base64,'.length));
	const asArray = new Uint8Array(data.length);

	for (let i = 0, len = data.length; i < len; ++i) {
		asArray[i] = data.charCodeAt(i);
	}

	return new Blob([asArray.buffer], {type: 'image/png'});
}

export function sliceCountryData(data) {
	var keysSorted = Object.keys(data).sort(function(a,b){return data[b]-data[a]});

	var sliced_data = keysSorted.slice(0, 10).map(key => ({
			code: key,
			title: key,
			value: data[key]
	}));
	return sliced_data;

}

export const checkFileExtension = (filename, extension) => {
	return filename.split('.').pop() === extension;
}

export const checkCSVFile = file => {
	return file.name.split('.').pop() === 'csv';
}

export const populateComscoreNodes = (treeData, nodeIDs) => {
	if (_.isEmpty(treeData) || _.isEmpty(nodeIDs)) return [];
	const nodesArray = [];
	const checkSelectedNodes = node => {
		_.each(node.children, child => {
			if (_.includes(nodeIDs, child.id)) nodesArray.push(child);
			else if (!_.isEmpty(child.children)) checkSelectedNodes(child);
		})
	}
	_.each(treeData['us'], node => {
		if (_.includes(nodeIDs, node.id)) nodesArray.push(node);
		else if (!_.isEmpty(node.children)) checkSelectedNodes(node);
	})
	return nodesArray;
}

export const createComscoreTreeStrings = (treeData, region) => {
	if (_.isEmpty(treeData)) return {};
	const stringsList = {};
	_.each(treeData[region], nodeOne => {
		// nodeOne (viewwing behaviours, finance, etc) is not a selectable node hence not added to the search
		if (!_.isEmpty(nodeOne.children)) {
			_.each(nodeOne.children, nodeTwo => {
				stringsList[`${nodeOne.value} > ${nodeTwo.value}`] = {  value: nodeTwo.value, id: nodeTwo.id, parentNodes: [nodeOne]};
				if (!_.isEmpty(nodeTwo.children)) {
					_.each(nodeTwo.children, nodeThree => {
						stringsList[`${nodeOne.value} > ${nodeTwo.value} > ${nodeThree.value}`] = {  value: nodeThree.value, id: nodeThree.id, parentNodes: [nodeOne, nodeTwo]};
						if (!_.isEmpty(nodeThree.children)) {
							_.each(nodeThree.children, nodeFour => {
								stringsList[`${nodeOne.value} > ${nodeTwo.value} > ${nodeThree.value} > ${nodeFour.value}`] = { value: nodeFour.value, id: nodeFour.id, parentNodes: [nodeOne, nodeTwo, nodeThree]};
							});
						}
					});
				}
			});
		}
	});
	return stringsList;
}
