import BaseDataProvider from '../../core/BaseDataProvider';
import _ from 'lodash';
import { YTChannel } from '../../models/ChannelModel';
import WhiteLabelSiteModel from '../../models/WhiteLabelSiteModel';
import { GoogleAdsCIDModel, GoogleAdsCampaignModel, GoogleAdsAdGroupModel } from '../../models/GoogleAdsModels';

class AuthDataProvider extends BaseDataProvider {

	//Login/Registration

	login(username, password) {
		return this.postLogin('/api/v1/auth/', {username: username, password: password}, false);
	}

	sendAuthMethod(authToken, method) {
		return this.postLogin('/api/v1/auth/', {auth_token: authToken, mfa_type: method}, false);
	}
	
	sendVerificationCode(authToken, session, code) {
		return this.postLogin('/api/v1/auth/', {auth_token: authToken, session: session, answer: code}, false)
	}

	loginWithGoogle(token) {
		return this.postLogin('/api/v1/auth/', {token: token}, false);
	}

	autoLogin(token) {
		return this.postLogin('/api/v1/auth/', {auth_token: token}, false);
	}

	register(data) {
		return this.post('/api/v1/users/', data, false);
	}

	updateCurrentUser(data) {
		return this.put('/api/v1/users/me/', data);
	}

	updateUserAvatar(img) {
		return this.imageUpload('/api/v1/users/me/avatar/', img);
	}

	deleteUserAvatar() {
		return this.delete('/api/v1/users/me/avatar/');
	}

	resetPassword(email) {
		return this.post('/api/v1/password_reset/', {email}, false);
	}

	changePassword(email, token, password) {
		return this.post('/api/v1/set_password/', {new_password: password, email, token}, false);
	}

	loginAsUser(userID) {
		return this.get(`/api/v1/admin/users/${userID}/auth/`);
	}

	changeUserPassword(currentPassword, newPassword) {
		return this.post('/api/v1/change_password/', {old_password: currentPassword, new_password: newPassword});
	}

	userLogout() {
		return this.delete('/api/v1/auth/');
	}

	getUserRegions() {
		return this.get('/api/v1/user_regions/');
	}

	getRoleRegions() {
		return this.get('/api/v1/role_regions/');
	}

	//Authorized Channels

	getAuthenticatedChannels() {
		return this.get('/api/v1/channels/?flat=1&own_channels=1').then(data => _.map(data.items, YTChannel.fromJSON.bind(YTChannel)));
	}

	sendChannelAuthentication(response) {
		return this.post('/api/v1/channels/authentication/', response);
	}

	revokeChannelAuth(channel) {
		return this.delete(`/api/v1/channels/${channel.id}/`);
	}

	//Contact Us Modal

	submitContactForm(form) {
		const { firstName, lastName, email, company, country, message } = form;
		const data = {first_name: firstName, last_name: lastName, email, company, country, message};
		return this.post('/api/v1/contact_forms/', data);
	}

	onGDPRCookiesAction(response) {
		if (response) {
			return this.put('/api/v1/users/me/', { has_accepted_GDPR: true });
		}
		return this.put('/api/v1/users/me/', { has_accepted_GDPR: false });
	}

	//OAuth
	fetchOAuthUserData() {
		return this.get('/api/v1/oauth/oauth_accounts/');
	}
	
	getGoogleAdsAccounts(code) {
		const href = encodeURIComponent(`${window.location.origin}${window.location.pathname.replace(/\/?(\?|#|$)/, '/$1')}?oauth_code=aw_auth`);
		return this.post(`/api/v1/oauth/aw_auth/?redirect_url=${href}`, code);
	}

	getDV360Accounts(code) {
		const href = encodeURIComponent(`${window.location.origin}${window.location.pathname.replace(/\/?(\?|#|$)/, '/$1')}?oauth_code=dv360_auth`);
		return this.post(`/api/v1/oauth/dv360_auth/?redirect_url=${href}`, code);
	}

	unlinkAccount(accountID) {
		return this.patch(`/api/v1/oauth/oauth_accounts/${accountID}/`, {is_enabled: false});
	}

	fetchCampaignsBasedOnOAuthType(oauthType, parentId) {
		let uri = `/api/v1/oauth/${oauthType?'line_items':'campaigns'}/?oauth_type=${oauthType}`;
		if (typeof parentId === 'number') uri += `&parent_id=${parentId}`;
		return this.get(uri);
	}

	fetchOAuthAdGroups(oauthType, parentId) {
		return this.get(`/api/v1/oauth/adgroups/?oauth_type=${oauthType}&parent_id=${parentId}`);
	}

	// White label site assets

	getSiteAssets() {
		return this.get('/api/v1/config/').then(data => WhiteLabelSiteModel.fromJSON(data));
	}

	// GoogleAds Push V2

	fetchGAdsCID(search=null) {
		if (!_.isEmpty(search)) {
			return this.get(`/api/v1/oauth/gads_accounts/?page=1&search=${search}`)
				.then(data => this.mapResponseItemsToObject(data.items, GoogleAdsCIDModel));
		}
		return this.get('/api/v1/oauth/gads_accounts/?page=1')
			.then(data => this.mapResponseItemsToObject(data.items, GoogleAdsCIDModel));
	}

	fetchGAdsCampaigns(cidID) {
		return this.get(`/api/v1/oauth/campaigns/?oauth_type=0&parent_id=${cidID}`)
			.then(data => this.mapResponseItemsToObject(data, GoogleAdsCampaignModel));
	}

	fetchGAdsAdGroupsByCampaign(campaignID) {
		return this.get(`/api/v1/oauth/adgroups/?oauth_type=0&campaign_id=${campaignID}`)
			.then(data => this.mapResponseItemsToObject(data, GoogleAdsAdGroupModel));
	}

	fetchGAdsAdGroupsByCID(cidID) {
		return this.get(`/api/v1/oauth/adgroups/?oauth_type=0&account_id=${cidID}`)
			.then(data => this.mapResponseItemsToObject(data, GoogleAdsAdGroupModel));
	}

	pushPlacementsToServerV2(segmentId, adgroups, exclusionId=null) {
		const body = {
			adgroup_ids: adgroups,
			segment_id: segmentId
		};
		if (_.isNumber(exclusionId)) body['exclusion_list_id'] = exclusionId;
		return this.post('/api/v2/segments/sync/gads/', body);
	}

}

const provider = new AuthDataProvider();
export default provider;
