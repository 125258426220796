import BaseDataProvider from '../../core/BaseDataProvider';
import _ from 'lodash';
import { Country } from '../../models/BaseModels';
import { WikipediaArticleModel } from '../../models/AdministrativeModels';

class UtilsDataProvider extends BaseDataProvider {

	getCountriesList() {
		return this.get('/api/v1/countries/').then(data => _.map(data, Country.fromJSON.bind(Country)));
	}

	getLanguagesList() {
		return this.get('/api/v1/languages/');
	}

	getAllProductTypes() {
		return this.get('/api/v1/iq_product/');
	}

	sendErrorReport(email, message) {
		let string = 'Unknown error';
		if(!_.isString(message)) {
			try {
				string = JSON.stringify(message);
			} catch (e) {}
		} else {
			string = message;
		}
		return this.post('/api/v1/error_report/', {email, message: string}, false);
	}

	createUserAction(data) {
		return this.post('/api/v1/admin/user_actions/', data);
	}

	getCampaignLocations(keyword) {
		return this.get(`/api/v1/geo_target_list/?search=${keyword}`)
			.then(data => _.map(data, ({ id, name, target_type }) => ({id, title: name, description: target_type})));
	}

	getKeywordInfoFromWiki(title) {
		return this.get(`https://en.wikipedia.org/w/api.php?action=query&origin=*&generator=search&format=json&gsrsearch=${title}&gsrwhat=text&gsrlimit=2&prop=categories&cllimit=5`, false)
			.then((categories) => {
				const pages = _.get(categories, 'query.pages');
				const escapeCategory = new RegExp('disambiguation');
				return _.find(_.keys(pages), (pageId) => {
					return !escapeCategory.test(_.get(pages[pageId], 'categories[0]title'))
				});
			})
			.then(pageKey => this.get(`https://en.wikipedia.org/w/api.php?action=query&format=json&origin=*&prop=extracts%7Cinfo%7Cpageimages
				&pageids=${pageKey}&exintro=1&explaintext=1&inprop=url%7Cdisplaytitle&piprop=thumbnail%7Cname%7Coriginal&pithumbsize=300`, false))
			.then(WikipediaArticleModel.fromJSON.bind(WikipediaArticleModel));
	}

}

const provider = new UtilsDataProvider();
export default provider;
